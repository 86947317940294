import type { TWebsiteConfig } from '~/typings/types/website-config.types';
import useDialogs from '~/composables/organisms/useDialogs';

/**
 * @description Form
 */
export type TForm = {
  name: string;
  title: string;
  subtitle: string;
  component: string;
};

/**
 * @description Form list
 */
export type TFormList = {
  [key: string]: TForm;
};

/**
 * @description Form names
 */
export enum EFormNames {
  FORM_CASHBACK = 'FORM_CASHBACK',
  FORM_CALLMASTER = 'FORM_CALLMASTER',
  FORM_OTK = 'FORM_OTK',
  FORM_FAQ_QUESTION = 'FORM_FAQ_QUESTION',
  FORM_REQUEST_COURSE_MATERIALS = 'FORM_REQUEST_COURSE_MATERIALS',
}

export interface IUseForms {
  /**
   * @description Список доступных на сайте форм.
   */
  formList: TFormList;
  /**
   * @description Функция заносит в state необходимую форму, далее эту форму можно асинхронно подгрузить в нужном месте.
   * @param {TForm} form - Данные формы из formList
   */
  requireForm(form: TForm): void;
}

/**
 * @description Composable функция для работы с формами на сайте.
 * @returns {IUseForms}
 */
export default function useForms(): IUseForms {
  const websiteConfig = useState<TWebsiteConfig>('config');
  const { openFormDialog } = useDialogs();

  const formList = {
    FORM_CASHBACK: {
      name: 'Cashback',
      title: 'Data for receiving cashback',
      subtitle: 'The form is required!',
      component: 'MFormCashback',
    },
    FORM_CALLMASTER: {
      name: 'Call master',
      title: 'Need repairs?',
      subtitle: 'Leave a request, we will contact you',
      component: 'MFormCallMaster',
    },
    FORM_OTK: {
      name: 'Technical Control Department',
      title: 'Write a review',
      subtitle: 'Tell us about your impressions',
      component: 'MFormOTK',
    },
    FORM_FAQ_QUESTION: {
      name: 'Questions',
      title: 'Any questions left?',
      subtitle: 'Our specialist will contact you',
      component: 'MFormFAQQuestion',
    },
    FORM_REQUEST_COURSE_MATERIALS: {
      name: 'Request for course materials',
      title: 'Get course materials',
      subtitle: 'Videos, presentations, detailed program',
      component: 'MFormRequestCourseMaterials',
    },
  };

  function requireForm(form: TForm) {
    openFormDialog();
    websiteConfig.value.dialogs.forms.requiredForm = form;
  }

  return {
    formList,
    requireForm,
  };
}
